(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var $ = (window.jQuery);

var url = require('url');
var _ = require('lodash');
var log = require('./log');
var filters = require('../settings').filters;

module.exports = function(indicatorVisibility) {
  this.init = function() {
    var self = this;
    var $inputs = $('input:checkbox[data-risk-filter]');

    /**
     * Update UI to reflect filtered state.
     */
    var state = self.get();
    _.each(state, function(state, level) {
      self.set(level, state);
      setRiskLevelVisibility(level, state);
    });

    if (!$inputs.length > 0) {
      return;
    }

    $inputs 
      .each(function() {
        /**
         * Reflect the actually state in the UI.
         */
        var $this = $(this);
        var level = $this.data('risk-filter');
        var checked = state[level];

        $this.prop('checked', checked);
      })
      .click(function() {
        /**
         * Update state via UI.
         */
        var $this = $(this);
        var level = $this.data('risk-filter');
        var checked = $this.prop('checked');
        //log(level, 'clicked ->', checked);

        self.set(level, checked);
        setRiskLevelVisibility(level, checked);
      });

    /**
     * Hides the appropriate risk level in the DOM.
     *
     * @param {string} - the risk level to hide/show. Can be one of:
     *   low
     *   medium
     *   high
     * @param {boolean} - either hide (false) or show (true).
     */
    function setRiskLevelVisibility(level, show) {
      //log('setRiskLevelVisibility ->', level + ':', show);
      var action = 'show';
      if (!show) {
        action = 'hide';
      }
      indicatorVisibility(level, action);
    }
  };

  /**
   * Saves the state of risk levels.
   *
   * @param {string} - the risk level to hide/show. Can be one of:
   *   low
   *   medium
   *   high
   * @param {boolean} - either hide (false) or show (true).
   */
  this.set = function(level, on) {
    var $link = $('.link--button--download-container a'); 
    // Get the current state.
    var state = this.get();

    // Use the part right after '/analysis/' as a key.
    var urlPieces = url.parse(document.URL);
    var key = urlPieces.pathname.split('/')[2];

    // Update it.
    state[level] = on;

    // Update the URL
    History.pushState(state, null, '?' + $.param(state));

    // Update PDF link.
    var linkPieces = url.parse($link.attr('href'));
    linkPieces.query = null;
    linkPieces.search = '?' + $.param(state);
    var link = url.format(linkPieces);
    $link.attr('href', link);

    // Persist to sessionStorage. Key the storage by each analysis
    var store = JSON.parse(sessionStorage.getItem('sraAnalysisFilter')) || {};
    store[key] = state;
    sessionStorage.setItem('sraAnalysisFilter', JSON.stringify(store));
  };

  /**
   * Get the current state.
   *
   * @return {object} - the risk level state object.
   */
  this.get = function() {

    // Use the part right after '/analysis/' as a key.
    var urlPieces = url.parse(document.URL, true);
    var key = urlPieces.pathname.split('/')[2];

    /**
     * Local Storage could be null and if that's the case let's stub an empty
     * object for this analysis.
     */
    var storage = JSON.parse(sessionStorage.getItem('sraAnalysisFilter'));
    if (!storage) {
      storage = {};
      storage[key] = {};
    }

    var localState = storage[key];
    var urlState = _.reduce(urlPieces.query, function(s, state, level) {
      s[level] = state === 'true'? true : false;
      return s;
    }, {});

    /**
     * Build the state giving priority highest to lowest:
     *   - URL
     *   - localStroage
     *   - default (true/on)
     */
    return _.reduce(filters, function(state, filter) {
      filter = filter.toLowerCase().replace(/\s/g, '');

      // set this filter to the URL state, otherwise the sessionStorage state.
      if (typeof urlState[filter] !== 'undefined') {
        state[filter] = urlState[filter];
      }
      else if (typeof localState[filter] !== 'undefined') {
        state[filter] = localState[filter];
      }
      else {
        state[filter] = true;
      }

      return state;
    }, {});
  };
};


}).call(this,require("1YiZ5S"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/lib/RiskLevel.js","/lib")