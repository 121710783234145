(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var $ = (window.jQuery);
var log = require('./log');
module.exports = function(handling) {
  var $this = $(this);
  // log('handleEmpty -> ', $this);

  var ident = $this.attr('id').split('-').pop();

  // The menu id for themes is plural but not for criteria.
  var menu = handling === 'theme'? 'themes' : handling;

  // Look for these inside $this
  var lookFor = handling === 'theme'? '.criteria' : '.indicator';

  /**
   * Showing is the number of still visible.
   */
  var showing = $this
    .find(lookFor + '.isShowing')
    .length;
  // log(showing, 'visible in', $this.attr('id'));

  var $thisMenu = $('#menu-' + menu);

  if (showing === 0) {
    // log('indicatorVisibility -> hiding', $this.attr('id'));
    $this.hide();
    $thisMenu.find('[data-' + handling + '=' + ident + ']')
      .addClass('sra-filter-empty')
      .removeClass('sra-filter-full');
  } else {
    // log('indicatorVisibility -> showing', $this.attr('id'));
    $this.show();
    $thisMenu.find('[data-' + handling + '=' + ident + ']')
      .addClass('sra-filter-full')
      .removeClass('sra-filter-empty');
  }
};


}).call(this,require("1YiZ5S"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/lib/handleEmpty.js","/lib")