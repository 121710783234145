(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var log = require('./log');
/**
 * Toggle visibility of an element.
 * 
 * @param $el - jQuery Object.
 * @param action - 'show' or 'hide' 
 */
module.exports = function($el, action) {
  if (action === 'hide') {
    $el
      .removeClass('isShowing')
      .addClass('isHidden')
  } else {
    $el
      .removeClass('isHidden')
      .addClass('isShowing')
  }
};

}).call(this,require("1YiZ5S"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/lib/toggleVisibility.js","/lib")