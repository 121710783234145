(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var $ = (window.jQuery);
var toggleVisibility = require('../toggleVisibility');
var handleEmpty = require('../handleEmpty');
var log = require('../log');

/**
 * Shows or hides the given filter level. This is defined
 * differently according to the context that it is in.
 *
 * @param {string} level  - The filter level to be affected.
 * @param {string} action - show|hide
 */
module.exports = function(level, action) {
  /**
   * Find all the .impact--[level] and hide their children because if we
   * hide the whole container the fancy scrolling functionality loses them
   * and does weird things when they come back.
   */
  log('indicatorVisibility (data)->', action + 'ing', level);
  var $indicator = $('.impact--' + level);
  var $criteria = $indicator.closest('.criteria');

  $indicator.children()[action]();
  toggleVisibility($criteria, action);
  toggleVisibility($indicator, action);

  /**
   * Update interface to reflect empty themes.
   */
  $('.pane-sra-analysis-data-analysis-data .theme').each(function() {
    handleEmpty.call(this, 'theme');
  });

  /**
   * Update interface to refelect empty criteria.
   */
  $('section.criteria').each(function() {
    handleEmpty.call(this, 'criteria');
  });

};

}).call(this,require("1YiZ5S"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/lib/indicatorVisibility/data.js","/lib/indicatorVisibility")