(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
/**
 * debug aware logging.
 */
module.exports = function(/* ...args */) {
  var DEBUG = require('../settings').DEBUG;
  if (!can_log) { return; }
  if (!DEBUG)   { return; }
  var args = Array.prototype.slice.call(arguments);
  console.log.apply(console, ['analysis_data_filter.js:'].concat(args));
};


}).call(this,require("1YiZ5S"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/lib/log.js","/lib")