(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var $ = (window.jQuery);
var toggleVisibility = require('../toggleVisibility');
var handleEmpty = require('../handleEmpty');
var log = require('../log');

/**
 * Shows or hides the given filter level. This is defined
 * differently according to the context that it is in.
 *
 * @param {string} level  - The filter level to be affected.
 * @param {string} action - show|hide
 */
module.exports = function(level, action) {
  $('.score-summary-table .row--indicator').each(function() {
    var $this = $(this);
    var $impact = $this.find('.impact--' + level)[action]();
    var $name = $this.find('.name');

    var $criteria = $this.parent('.row--criteria');
    log('/ndicatorVisibility (score)->', $criteria);

    if ($impact.length !== 0) {
      if ($name.text().trim() === '') {
        log('indicatorVisibility (score)-> hiding <tr>');
        $this[action]();
      } else {
        log('indicatorVisibility (score)-> hiding .indicator');
        $this.find('.indicator')[action]();
      }

      /**
       * Now check if the only visible thing is the not-empty .name
       */
      var stillVisible = $criteria.find('td:visible').length;
      log('indicatorVisibility (score)-> still visible in row:', stillVisible);
      if (stillVisible === 1) {
        log('indicatorVisibility (score)-> hiding criteria.');
        toggleVisibility($criteria, 'hide');
        $criteria.hide();
      } else {
        log('indicatorVisibility (score)-> showing criteria');
        toggleVisibility($criteria, 'show');
        $criteria.show();
      }

    }
  });

  $('.section--theme').each(function() {
    var $theme = $(this);
    var $visible = $theme
      .find('.score-summary-table').children('tbody.isShowing');

    log('indicatorVisibility (score)->', $visible.length, 'left in', $theme.attr('id'));
    if ($visible.length <= 0) {
    log('indicatorVisibility (score)->', 'hiding', $theme.attr('id'));
      $theme.hide();
    } else {
      log('indicatorVisibility (score)->', 'showing', $theme.attr('id'));
      $theme.show();
    }
  });
};

}).call(this,require("1YiZ5S"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/lib/indicatorVisibility/score.js","/lib/indicatorVisibility")