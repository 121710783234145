(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
/**
 * This JavaScript file provides functionality for hiding and showing different
 * risk levels. It handles the score summary page the data analysis page and
 * PDF exports.
 * @file
 */

var $ = (window.jQuery);
var log = require('./lib/log');
var filters = require('./settings').filters;
var _ = require('lodash');

$(function() {
  /**
   * RiskLevel is a class that takes an indicatorVisibility function
   * which are found in `./lib/indicatorVisibility`. This is because each page
   * requires different logic for showing and hiding elements.
   *
   * So we instantiate the classes with the appropriate function and then call
   * .init to execute the logic for that page.
   */
  var RiskLevel = require('./lib/RiskLevel');

  /**
   * Controls to be inserted somewhere appropriate. Could be placed by anything
   * not just JS. The key is data-risk-filter.
   */
  var controlsHtml = _.reduce(filters, function(html, filter) {
    var dataAttr = filter.toLowerCase().replace(/\s/g, '');
    html += '<input checked type="checkbox" class="' + dataAttr +
              '" data-risk-filter="' + dataAttr +
              '"> ' + filter + '</input> ';
    return html;
  }, '');
  controlsHtml = '<div class="risk-filter"><span>Filter by Risk Level: </span>' + controlsHtml + '</div>'
  var $checkboxes = $(controlsHtml);

  /**
   * This script runs in different contexts and an effort has been made to
   * place all the complexity of that into this centralized switch statement.
   *
   * split the url by /'s
   * grab the last item
   * split that by #'s because there could be an in-page id link
   * grab the first part of that.
   */
  var context = document.URL.split('/')[5];

  switch (context) {
    /**
     * In the case of the Analysis Data Page
     */
    case 'data':
      log('pageLoad -> context data');
      $('.report-links').after($checkboxes);

      var dataPage = new RiskLevel(require('./lib/indicatorVisibility/data'));
      dataPage.init();
      break;

    /**
     * In the case of the Score Summary Page
     */
    case 'score':
      log('pageLoad -> context score');
      $('.report-links').after($checkboxes);

      var scorePage = new RiskLevel(require('./lib/indicatorVisibility/score'));
      scorePage.init();
      break;

    case 'view':
      log('pageLoad -> context view/pdf');
      var scorePage = new RiskLevel(require('./lib/indicatorVisibility/score'));
      var dataPage = new RiskLevel(require('./lib/indicatorVisibility/data'));
      scorePage.init();
      dataPage.init();
      break;
  }

});

}).call(this,require("1YiZ5S"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_53107c0c.js","/")